// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app_name: 'Principes',
  endpoint: 'https://api.falange.nerano.app.br/',
  endpoint3: 'http://localhost:8000',
  endpoint1: 'http://192.168.1.109:8000',
  recaptchaCode: '6LfKfa8cAAAAADrrKxpZfcFHQZBTTyHJJBqSvIhr',
  gmApiKey: 'AIzaSyBRwBvqupemLTLukrvlsQdryuzvSydOkr0',
  kweather: 'a71ded436069d445d03d623645ad7be77f8e3b70c87c8db0a32d4002395c4b15'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
