import { environment } from './../environments/environment';
import { Component, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = environment.app_name;
  public isLogin = false;
  public isLoading = true;

  constructor(
    private active: ActivatedRoute,
    private router: Router,
    public viewContainerRef: ViewContainerRef, // para gerar loading é obrigado a instanciar isso (é capturado isso lá)
  ) {
    this.isLogin = (window.location.pathname === '/login') ? true : false;

    this.router.events.subscribe((observer) => {
      if (observer instanceof NavigationEnd) {
        this.isLogin = (observer.url === '/login') ? true : false;
      }
    });
  }
}
