<mat-drawer-container class="menu-container">
  <mat-drawer #drawer class="menu-sidenav" mode="over">
    <mat-action-list>
      <button
        mat-list-item
        *ngFor="let item of rotas"
        (click)="goTo(item.router)"
      >
        {{ item.name }}
      </button>
    </mat-action-list>
  </mat-drawer>
  <mat-drawer-content>
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>{{ appName }}</span>
      <span class="spacer"></span>
      <span>{{ nome ? nome : login }}</span>
      <button mat-icon-button>
        <mat-icon>favorite</mat-icon>
      </button>
    </mat-toolbar>
    <div class="wrapper">
      <ng-content></ng-content>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
