import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KonsoleService {

  constructor() { }

  public log(msg: string): void {
    console.log(
      "%cLog:",
      "color:white;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
    console.log(
      "%c" + msg,
      "color:white;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
    console.log(
      "%c ======================= ",
      "color:white;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
  }

  public error(msg: string): void {
    console.log(
      "%cErro:",
      "color:red;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
    console.log(
      "%c" + msg,
      "color:white;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
    console.log(
      "%c ======================= ",
      "color:red;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
  }

  public info(msg: string): void {
    console.log(
      "%cInfo:",
      "color:blue;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
    console.log(
      "%c" + msg,
      "color:white;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );

    console.log(
      "%c ======================= ",
      "color:blue;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
  }

  public success(msg: string): void {
    console.log(
      "%cSucesso:",
      "color:green;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
    console.log(
      "%c" + msg,
      "color:white;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
    console.log(
      "%c ======================= ",
      "color:green;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold"
    );
  }

}
