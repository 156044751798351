import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './shared/auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'jaguar',
    loadChildren: () => import('./views/jaguar/jaguar.module').then((m) => m.JaguarModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'vacina',
    loadChildren: () => import('./views/vacina/vacina.module').then((m) => m.VacinaModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'usuario',
    loadChildren: () => import('./views/usuario/usuario.module').then((m) => m.UsuarioModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'rotinas',
    loadChildren: () => import('./views/rotinas/rotinas.module').then((m) => m.RotinasModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'tempo',
    loadChildren: () => import('./views/tempo/tempo.module').then((m) => m.TempoModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'financeiro',
    loadChildren: () => import('./views/financeiro/financeiro.module').then((m) => m.FinanceiroModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
