import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuModule } from './shared/menu/menu.module';
import { KonsoleService } from './shared/konsole/konsole.service';
import { AuthGuardService } from './shared/auth/auth-guard.service';
import { CommonModule } from '@angular/common';
import { MatPaginatorIntlPtBr } from './shared/paginator-ptbr/MatPaginatorIntlPtBr';
import { MatPaginatorIntl } from '@angular/material/paginator';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MenuModule,
  ],
  providers: [
    KonsoleService,
    AuthGuardService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPtBr},
  ],
  bootstrap: [AppComponent],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
