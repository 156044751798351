import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { KonsoleService } from '../konsole/konsole.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @ViewChild('drawer', { read: MatDrawer}) private drawer: MatDrawer;
  @Input('app_name') public appName;
  public login;
  public nome;

  public rotas: any = [
    {
      name : 'Página inicial',
      router : ['/']
    },
    {
      name : 'Tempo',
      router : ['/tempo']
    },
    {
      name : 'Membros',
      router : ['/jaguar']
    },
    {
      name : 'Financeiro',
      router : ['/financeiro']
    },
    {
      name : 'Vacinados',
      router : ['vacina']
    },
    {
      name : 'Usuários',
      router : ['usuario/listar']
    },
    {
      name : 'Rotinas',
      router : ['rotinas']
    },
    {
      name : 'Sair',
      router : ['login/sair']
    }
  ];

  constructor(
    private konsole: KonsoleService,
    private router: Router
  ) {
    this.login = localStorage.getItem('nerano-login');
    this.nome = localStorage.getItem('nerano-nome');
  }

  public ngOnInit(): void {
    // this.konsole.log("exemplo");
    // this.konsole.error("exemplo");
    // this.konsole.info("exemplo");
    // this.konsole.success("exemplo");
  }

  public goTo(router: any): void {
    if (router) {
      this.router.navigate(router);
      this.drawer.close();
    }
  }

}
